import { Cookies } from 'react-cookie';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { FormatRupiah } from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { header, numTable, swalCustom, headerStickLeft, tdStickLeft } from '../../../components/CustomStyle';
import { DeleteAnggaran, GetAnggaranListV2, GetAnggaranPeriodeNow, GetPeriodeList, GetSinkronApple, GetDetailAnggaran, GetSinkronKegiatanApple } from "../../../services/KeuanganService";

const AnggaranList = () => {
    const url = '/anggaran/detail/'
    const url_edit = '/anggaran/edit/'

    const navigate = useNavigate();
    let { SearchBar } = Search;

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let homebase_nama = cookies.get('homebase_nama');

    let [page, setPage] = useState(1);
    let [periode, setPeriode] = useState(0);
    let [spinIcon, setSpinIcon] = useState(0);
    let [totalAgr, setTotalAgr] = useState(0);
    let [periodeNow, setPeriodeNow] = useState(0);

    let [data, setData] = useState([]);
    let [dataDetail, setDataDetail] = useState([]);
    let [periodeList, setPeriodeList] = useState([]);

    const getData = async () => {
        await GetAnggaranListV2(homebase, periode).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetAnggaranListV2'));
    }

    const getDetail = async () => {
        await GetDetailAnggaran(homebase, periode).then((response) => {
            setDataDetail(response.data.rows);
            setTotalAgr(response.data.total);

        }).catch(() => console.log('error GetDetailAnggaran'));
    }

    const getSinkronApple = async () => {
        await GetSinkronApple(homebase, periode).then((response) => {
            if (response.data.status == 200) {
                getData();
                setSpinIcon(0);
            }
        }).catch(err => {
            setSpinIcon(0);
            console.log('error GetSinkronApple');
        });
    }

    const getSinkronKegiatanApple = async () => {
        await GetSinkronKegiatanApple(homebase, periode).then((response) => {
            if (response.data.status == 200) {
                getDetail();
                setSpinIcon(0);
            } else {
                setDataDetail([]);
                setSpinIcon(0);
            }

        }).catch(err => {
            setDataDetail([]);
            setSpinIcon(0);
            console.log('error GetSinkronKegiatanApple');
        });
    }

    function sinkronApple() {
        setSpinIcon(1);
        getSinkronApple();
        getSinkronKegiatanApple();
    }

    const getPeriode = async () => {
        await GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error GetPeriodeList'));
    }

    const getPeriodeNow = async () => {
        await GetAnggaranPeriodeNow(homebase).then((response) => {
            setPeriodeNow(response.data.rows['id']);

        }).catch(() => console.log('error GetAnggaranPeriodeNow'));
    }

    useEffect(() => {
        getPeriode();
        getPeriodeNow();
    }, [homebase])

    useEffect(() => {
        getData();
        getDetail();
    }, [homebase, periode])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteAnggaran(homebase, id).then(response => {
                    if (response.status == 204) {
                        swalCustom.fire('Berhasil dihapus!', '', 'success')
                        getData();
                    }
                }).catch(err => swalCustom.fire(err.response.data.message, '', 'error'));
            }
        })
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
                {row.jenis_anggaran == 'Apple' ?
                    <><span className="badge badge-sm badge-primary">{row.jenis_anggaran}</span></> :
                    <><span className="badge badge-sm badge-info">{row.jenis_anggaran}</span></>}
                <br />
                {row.nama_kegiatan}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kegiatan',
        dataField: 'nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return <>{row.nama_kegiatan}</>
        },
        formatter: namaTruncate,
        style: {
            width: 500,
            whiteSpace: 'normal'
        },
        headerStyle: header
    }, {
        text: 'Nominal',
        dataField: 'total_anggaran',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_anggaran} /></b>
        },
        headerStyle: header
    }, {
        text: 'Terpakai',
        dataField: 'total_pengeluaran',
        formatter: (cell, row, rowIndex) => {
            return <span style={{ color: 'red' }}><FormatRupiah value={row.total_pengeluaran} /></span>
        },
        headerStyle: header
    }, {
        text: 'Tersisa',
        dataField: 'sisa_dana',
        formatter: (cell, row, rowIndex) => {
            return <span className='text-success text-bold'><FormatRupiah value={row.sisa_dana} /></span>
        },
        headerStyle: header
    }
        // ,{
        //     text: 'Action',
        //     dataField: 'button',
        //     formatter: (cell, row, rowIndex) => {
        //         return <>
        //             {periode == periodeNow ? <>
        //             <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
        //                 <span className="material-icons md-18">edit</span>
        //             </Link>
        //             <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
        //                 <span className="material-icons md-18">delete</span>
        //             </button></>:null}
        //         </>
        //     },
        //     headerStyle: header
        // }
    ];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id + '/' + row.periode.id;
            navigate(path);
        }
    }

    function change(event) {
        setPeriode(event.target.value);
        setData([]);
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Anggaran</h5>
                                <span className="text-bold">{homebase} - {homebase_nama}</span>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <button onClick={sinkronApple} className="me-2 btn btn-xs btn-primary">
                                        {spinIcon == 0 ?
                                            <i className="fas fa-sync fa-lg me-1"></i> :
                                            <i className="fas fa-sync fa-lg me-1 fa-spin"></i>}
                                        Sinkron Apple
                                    </button>
                                    <Link to="/anggaran/form" className="btn btn-xs btn-success">
                                        <span className="material-icons md-18 me-1">add_box</span> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={change}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                )}
                            </select> : null}
                        <br />
                        <ToolkitProvider
                            keyField="id"
                            data={data}
                            columns={columns}
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar {...props.searchProps} />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            {...props.baseProps}
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                                }
                                            })}
                                            bordered={false}
                                            rowEvents={rowEvents}
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Daftar Kegiatan</h5>
                                <span className="text-bold">{homebase} - {homebase_nama}</span>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={headerStickLeft}>Nama Kegiatan</th>
                                        <th style={header}>Oktober</th>
                                        <th style={header}>November</th>
                                        <th style={header}>Desember</th>
                                        <th style={header}>Januari</th>
                                        <th style={header}>Februari</th>
                                        <th style={header}>Maret</th>
                                        <th style={header}>April</th>
                                        <th style={header}>Mei</th>
                                        <th style={header}>Juni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataDetail.map((row, rowIndex) =>
                                        <tr>
                                            <td className="text-sm font-weight-normal" style={tdStickLeft}>{row.nama}</td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#feebef', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[0].total > 0 ? <FormatRupiah value={row.detail[0].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#feebef', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[1].total > 0 ? <FormatRupiah value={row.detail[1].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#feebef', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[2].total > 0 ? <FormatRupiah value={row.detail[2].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eff1fc', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[3].total > 0 ? <FormatRupiah value={row.detail[3].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eff1fc', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[4].total > 0 ? <FormatRupiah value={row.detail[4].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eff1fc', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[5].total > 0 ? <FormatRupiah value={row.detail[5].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eafaf3', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[6].total > 0 ? <FormatRupiah value={row.detail[6].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eafaf3', borderRight: '1px solid #b9bfca' }}>
                                                {row.detail[7].total > 0 ? <FormatRupiah value={row.detail[7].total} /> : ''}
                                            </td>
                                            <td className="text-sm font-weight-normal" style={{ backgroundColor: '#eafaf3' }}>
                                                {row.detail[8].total > 0 ? <FormatRupiah value={row.detail[8].total} /> : ''}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <ul className="pagination justify-content-end mt-4">
                            <li>Total Anggaran <b><FormatRupiah value={totalAgr} /></b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranList